import React from 'react';
import { graphql } from 'gatsby';

import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';

import Helmet from 'react-helmet';
import Article from '@react-website-themes/classy-docs/components/Article';
import Bodytext from '@react-website-themes/classy-docs/components/Bodytext';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Footer from '@react-website-themes/classy-docs/components/Footer';
import Header from '@react-website-themes/classy-docs/components/Header';
import Heading from '@react-website-themes/classy-docs/components/Heading';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Menu from '@react-website-themes/classy-docs/components/Menu';
import { css } from 'emotion';

import config from 'meta/config';
import menuItems from 'meta/menu';

const HideCredits = css`
  .credits {
    visibility: hidden;
  }
`;

const NotFoundPage = (props) => {
  const {
    data: {
      notFound: {
        edges: notFoundEdges,
      },
      footerLinks: {
        edges: footerLinksEdges,
      },
      copyright: {
        edges: copyrightEdges,
      },
    },
  } = props;

  const {
    node: {
      body: {
        childMarkdownRemark: {
          html: footerLinksHTML,
        },
      },
    },
  } = footerLinksEdges[0];

  const {
    node: {
      body: {
        childMarkdownRemark: {
          html: copyrightHTML,
        },
      },
    },
  } = copyrightEdges[0];

  const {
    node: {
      body: {
        childMarkdownRemark: {
          html: notFoundHTML,
        },
      },
    },
  } = notFoundEdges[0];

  const { headerTitle, headerSubTitle } = config;

  return (
    <Layout>
      <Helmet title="Not Found | Able Policies" />
      <Header>
        <Branding title={headerTitle} subTitle={headerSubTitle} />
        <Menu items={menuItems} />
      </Header>
      <Article>
        <Heading title="NOT FOUND" />
        <Bodytext html={notFoundHTML} />
      </Article>
      <Footer customStyle={HideCredits} links={footerLinksHTML} copyright={copyrightHTML} />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    notFound: allContentfulParts(
      filter: {
            title: { eq: "notFound"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    footerLinks: allContentfulParts(
      filter: {
            title: { eq: "footerLinks"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    copyright: allContentfulParts(
      filter: {
            title: { eq: "copyrightNote"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
